import { rootCap } from '../base/rootCap';
import { scale1point2 } from '../base/scale.definitions';

/**
 * All availabe capSizes
 *
 * Factor of 1.2 between each size
 */
export const capSizes = {
	'-2 | smaller': rootCap(scale1point2['-2']),
	'-1 | small': rootCap(scale1point2['-1']),
	'0 | p': rootCap(scale1point2['0']),
	'1 | h3': rootCap(scale1point2['1']),
	'2 | h2': rootCap(scale1point2['2']),
	'3 | h1': rootCap(scale1point2['3']),
} as const;

export type CapSize = keyof typeof capSizes;

/**
 * All available line heights.
 *
 * `tight` should be used for short strings of text such as headings
 */
export const capLineHeights = {
	tight: String(scale1point2['4']),
	normal: String(scale1point2['5']),
} as const;

export const mapCapSizes = <Type,>(
	fn: (space: typeof capSizes[CapSize]) => Type
) => {
	return Object.fromEntries(
		Object.entries(capSizes).map(([key, value]) => [key, fn(value)])
	) as { [Key in CapSize]: Type };
};
