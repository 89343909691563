/**
 * Should be rarely used directly.
 *
 * Probably use `spaces` instead.
 */
export const scale1point2 = {
	'-2': 0.6944,
	'-1': 0.8333,
	'0': 1,
	'1': 1.2,
	'2': 1.44,
	'3': 1.728,
	'4': 2.074,
	'5': 2.488,
	'6': 2.986,
	'7': 3.583,
	'8': 4.299,
	'9': 5.159,
	'10': 6.191,
	'11': 7.429,
	'12': 8.915,
} as const;
